// ProductsData.js
export const polotshirt = [
    {
      id: 1,
      title: "Men Striped Polo Neck Cotton Blend White T-Shirt",
      image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/z/4/k/xl-t712-highkind-original-imah6xf3xmzpqcrq.jpeg?q=70&crop=false",
      images: [
        "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/z/4/k/xl-t712-highkind-original-imah6xf3xmzpqcrq.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/9/h/h/xl-t712-highkind-original-imah6xf38uyxzcty.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/3/2/v/xl-t712-highkind-original-imah6xf3h8v9cpsj.jpeg?q=70&crop=false",
          ],
      subtitle: "Highkind",
      discountPrice: "599.00",
      mainPrice: "1299",
      description: "Comfortable and stylish regular fit jeans with a light grey shade. Perfect for casual outings."
    },
    {
      id: 2,
      title: "Men Colorblock Polo Neck Cotton Blend Green T-Shirt",
      image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/9/q/i/xxl-nl-clts-801-garimaknitwear-original-imah2rgnfmc4gnng.jpeg?q=70&crop=false",
      images: [
        "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/9/q/i/xxl-nl-clts-801-garimaknitwear-original-imah2rgnfmc4gnng.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/b/j/q/xxl-nl-clts-801-garimaknitwear-original-imah2rgnbf7asygn.jpeg?q=70&crop=false",
         ],
      subtitle: "Garimaknitwear",
      discountPrice: "699.00",
      mainPrice: "1399",
      description: "Trendy full sleeve sweatshirt with printed design, perfect for casual wear during colder seasons."
    },
    {
        id: 3,
        title: "Men Striped Polo Neck Cotton Blend Black, Red T-Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/e/d/c/xxl-mens-strip-polo-hs-black-red-tb-blue-original-imahfdzkrtqyqtzx.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/e/d/c/xxl-mens-strip-polo-hs-black-red-tb-blue-original-imahfdzkrtqyqtzx.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/o/7/q/xxl-mens-strip-polo-hs-black-red-tb-blue-original-imahfdzkxjxgsb7g.jpeg?q=70&crop=false",
          ],
        subtitle: "TB Blue",
        discountPrice: "649.00",
        mainPrice: "1299",
        description: "A stylish, solid spread collar casual shirt for men, perfect for any casual occasion."
      },      
      {
        id: 4,
        title: "Men Typography Round Neck Cotton Blend Grey T-Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/c/p/f/xl-5447846746-try-this-original-imaghffrgxptyhat.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/c/p/f/xl-5447846746-try-this-original-imaghffrgxptyhat.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/o/e/c/xl-5447846746-try-this-original-imaghffrachunhhs.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/m/i/f/xl-5447846746-try-this-original-imaghffrzzmx6rrh.jpeg?q=70&crop=false",
        ],
        subtitle: "Try This ",
        discountPrice: "658.00",
        mainPrice: "999",
        description: "Classic formal shirt with a spread collar and solid design, made from soft Ramraj cotton."
      },
      {
        id: 5,
        title: "Men Striped Polo Neck Pure Cotton Blue T-Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/q/t/f/l-light-blue-magneto-original-imahy3fy9vwpazh4.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/q/t/f/l-light-blue-magneto-original-imahy3fy9vwpazh4.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/u/t/o/l-light-blue-magneto-original-imahy3fyxnfhyc2t.jpeg?q=70&crop=false",
        ],
        subtitle: "Magneto ",
        discountPrice: "499.00",
        mainPrice: "1499",
        description: "Stylish casual shirt with a solid design and spread collar from WOXEN."
      },
      {
        id: 6,
        title: "Men Striped Polo Neck Cotton Blend Multicolor T-Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/r/s/b/m-yellow-tiping-khahuriyo-original-imahfk4bv6bsvgcf.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/r/s/b/m-yellow-tiping-khahuriyo-original-imahfk4bv6bsvgcf.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/t/v/v/xxl-musterd-srkexim-original-imahfk2rscmatm48.jpeg?q=70&crop=false",
            ],
        subtitle: "KHAHURIYO ",
        discountPrice: "585.00",
        mainPrice: "1499",
        description: "Slim fit checkered shirt with a slim collar, perfect for casual wear."
      },
      {
        id: 7,
        title: "Men Solid Polo Neck Cotton Blend Red T-Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/z/c/r/s-mfd-s-mafurd-original-imah2d4teggphhpn.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/z/c/r/s-mfd-s-mafurd-original-imah2d4teggphhpn.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/r/3/r/s-mfd-s-mafurd-original-imah2d4twu2efbyk.jpeg?q=70&crop=false",
            ],
        subtitle: "MAFURD ",
        discountPrice: "592.00",
        mainPrice: "1299",
        description: "Solid mandarin collar casual shirt for a trendy and modern look."
      },
      {
        id: 8,
        title: "Men Embroidered Polo Neck Cotton Blend Black T-Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/o/t/3/m-men-polo-t-shirt-zilvee-original-imah2ycwjhtxzr88.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/o/t/3/m-men-polo-t-shirt-zilvee-original-imah2ycwjhtxzr88.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/t-shirt/9/x/l/m-ss-212-polo-neck-bliss-bell-original-imagvkqhdhrgcd3y.jpeg?q=70&crop=false",
          ],
        subtitle: "Zilvee ",
        discountPrice: "580.00",
        mainPrice: "999",
        description: "Solid spread collar casual shirt, perfect for a comfortable and trendy look."
      }
  ];
  