import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './carticon.css';

const CartIcon = () => {
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    // Function to update cart count from cartItems array in localStorage
    const updateCartCount = () => {
      const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const totalItemCount = cartItems.reduce((total, item) => total + (item.quantity || 1), 0);
      setCartCount(totalItemCount);
    };

    // Initial count update when the component mounts
    updateCartCount();

    // Set up an event listener to listen for changes in the cart items
    window.addEventListener('storage', updateCartCount);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('storage', updateCartCount);
    };
  }, []);

  return (
    <li className='carticon'>
      <Link to="/Checkout">
        <i className="fa fa-shopping-cart cart-icon"></i>
        {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
      </Link>
    </li>
  );
};

export default CartIcon;
