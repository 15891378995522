import React from "react";
import { useNavigate } from "react-router-dom";



export const calculateDiscount = (mainPrice, discountPrice) => {
  const discount = ((mainPrice - discountPrice) / mainPrice) * 100;
  return `${Math.round(discount)}% Off`;
};

const ProductSection = ({ products }) => {
  const navigate = useNavigate();

  const addToCart = (product, redirectToCheckout = false) => {
    const existingCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    // Check if the product already exists in the cart
    const existingProductIndex = existingCartItems.findIndex(
      (item) => item.id === product.id
    );

    if (existingProductIndex !== -1) {
      existingCartItems[existingProductIndex].quantity += 1;
    } else {
      existingCartItems.push({ ...product, quantity: 1 });
    }

    localStorage.setItem("cartItems", JSON.stringify(existingCartItems));

    if (redirectToCheckout) {
      localStorage.setItem("selectedProduct", JSON.stringify({ ...product, quantity: 1 }));
      navigate("/Productpage");
    } else {
      alert("Added to cart");
    }
  };

  const openProductPage = (product) => {
    navigate("/Productpage", { state: product });
  };

  return (
    <div className="product-section">
      {products.map((product) => (
        <div key={product.id} className="product">
          <div onClick={() => openProductPage(product)} className="product-link">
            <img src={product.image} alt={product.title} className="product-image" />

            <h4 className="product-title">{product.title}</h4>
            <h3 className="sub-title">{product.subtitle}</h3>
            <div className="price">
              <h3 className="main-pricee">
                Rs.{product.discountPrice} <span className="d-price">Rs.{product.mainPrice}</span>{" "}
                <span className="s-discount"> {calculateDiscount(product.mainPrice, product.discountPrice)}</span>
              </h3>
            </div>
          </div>
          <div className="button-group">
            <button className="cart-button add-to-cart-btn" onClick={() => addToCart(product)}>
              Add To Cart
            </button>
            <button
              className="cart-button add-to-cart-btn"
              onClick={() => openProductPage(product)}
            >
              Buy Now
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductSection;
