import React, { useState, useEffect } from 'react';
import './Aboutsection.css';

const AboutSection = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeIn(true); // Trigger fade-in effect after component is mounted
    }, 300);

    return () => clearTimeout(timeout); // Cleanup
  }, []);

  return (
    <section className={`about-section ${fadeIn ? 'fade-in' : ''}`}>
         <div className="about-img-wrapper">
          <img
            src="https://i.imgur.com/RRuK72k.jpeg" // Example image URL
            alt="Blas Blush Collection"
            className="about-img"
          />
        </div>
      <div className="about-content">
        <h2 className="about-title">About Blas Blush</h2>
        <p className="about-text">
          Blas Blush is a premium clothing startup, redefining fashion by blending modern elegance with old-fashioned charm. 
          Specializing in creating timeless attire for diverse occasions, we offer everything from traditional saris and suits to contemporary designs for weddings and special events.
        </p>
        <p className="about-text">
          Our designs are crafted from a rich variety of materials, including khadi, silk, cotton, banana fiber, and bamboo. 
          Blas Blush is committed to luxury and sustainability, proudly supporting the Khadi initiative led by the Bihar government. 
          We are passionate about eco-friendly practices and empowering local artisans.
        </p>
        <p className="about-text">
          Blas Blush is not just about clothing—it’s about celebrating traditions, embracing sustainability, and bringing timeless fashion to life for every occasion.
        </p>
        
       
      </div>
    </section>
  );
};

export default AboutSection;
