import React from "react";
import { Link } from 'react-router-dom';
import './footer.css';


const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-container">
        {/* First Card: Company, Help, Policy */}
        <div className="footer-card card-company">
          <div className="company"><h4>Company</h4>
          <ul>
            <li><a href="#">Contact Us</a></li>
           <li><Link to="/Aboutsection">About</Link></li>
            <li><a href="#">Careers</a></li>
          </ul></div>
          <div className="help"><h4>Help</h4>
          <ul>
            <li><a href="#">Payments</a></li>
            <li><a href="#">Shipping</a></li>
            <li><a href="#">Cancellation</a></li>
            <li><a href="#">FAQ</a></li>
          </ul></div>
          <div className="policy"><h4>Policy</h4>
          <ul>
            <li><a href="#">Return Policy</a></li>
            <li><a href="#">Terms Of Use</a></li>
            <li><a href="#">Security</a></li>
            <li><a href="#">Privacy</a></li>
          </ul></div>
        </div>

        {/* Second Card: Social */}
        <div className="footer-card card-social">
          <h4>Stay In Touch</h4>
         <div className="social"> <ul>
            <li><a href="https://www.facebook.com/blasblush" target="_blank" rel="noopener noreferrer"> <img src="https://img.icons8.com/?size=96&id=118497&format=png"/> Facebook</a></li>
            <li><a href="https://www.instagram.com/blas.blush/" target="_blank" rel="noopener noreferrer"><img src="https://img.icons8.com/?size=512&id=Xy10Jcu1L2Su&format=png"/> Instagram</a></li>
            <li><a href="#" > <img src="https://img.icons8.com/?size=512&id=19318&format=png"/> YouTube</a></li>
            <li><a href="https://www.linkedin.com/company/blasblush" target="_blank" rel="noopener noreferrer"> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRokEYt0yyh6uNDKL8uksVLlhZ35laKNQgZ9g&s"/> Linkedin</a></li>
            <li><a href="#" > <img src="https://img.freepik.com/premium-vector/new-twitter-logo-x-2023-twitter-x-logo-vector-download_691560-10794.jpg"/> Twitter</a></li>
            
        
          </ul></div>
        </div>

        {/* Third Card: Mail Us and Registered Office */}
        <div className="footer-card card-mail-office">
          <h4>Mail Us</h4>
          <address>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.<br />
            Ad culpa, ipsam porro ullam praesentium<br />
            eveniet impedit necessitatibus modi a iure.
          </address>
          <h4>Registered Office Address:</h4>
          <address>
            Lorem ipsum dolor sit.<br />
            Lorem ipsum dolor sit amet consectetur.<br />
            Lorem, ipsum dolor sit amet consectetur adipisicing.
          </address>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; 2024 All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
