// ProductsData.js
export const poloshirt = [
    {
      id: 1,
      title: "Men Regular Fit Solid Spread Collar Casual Shirt",
      image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/a/l/xxl-st43-vebnor-original-imah8736fyarwuhf.jpeg?q=70&crop=false",
      images: [
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/a/l/xxl-st43-vebnor-original-imah8736fyarwuhf.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/l/z/7/xxl-st43-vebnor-original-imah8736abujcmzp.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/r/d/a/xxl-st43-vebnor-original-imah8736znavncgz.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/p/k/q/xxl-st43-vebnor-original-imah8736yupsvrnz.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/x/y/w/xxl-st43-vebnor-original-imah8736wpf7tysb.jpeg?q=70&crop=false",
        ],
      subtitle: "VeBNoR ",
      discountPrice: "599.00",
      mainPrice: "1199",
      description: "Comfortable and stylish regular fit jeans with a light grey shade. Perfect for casual outings."
    },
    {
      id: 2,
      title: "Men Slim Fit Printed Spread Collar Casual Shirt",
      image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/p/l/g/l-kcsh-prm-sgnr-printt-nb-wh-flwr-fubar-original-imahyy3dk7vknryy.jpeg?q=70&crop=false",
      images: [
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/p/l/g/l-kcsh-prm-sgnr-printt-nb-wh-flwr-fubar-original-imahyy3dk7vknryy.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/5/1/x/l-kcsh-prm-sgnr-printt-nb-wh-flwr-fubar-original-imahyy3dfqpza7fg.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/6/s/2/l-kcsh-prm-sgnr-printt-nb-wh-flwr-fubar-original-imahyy3deqdjns7k.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/8/6/3/l-kcsh-prm-sgnr-printt-nb-wh-flwr-fubar-original-imahyy3df9nzfake.jpeg?q=70&crop=false"
      ],
      subtitle: "FUBAR ",
      discountPrice: "580.00",
      mainPrice: "1999",
      description: "Trendy full sleeve sweatshirt with printed design, perfect for casual wear during colder seasons."
    },
    {
        id: 3,
        title: "Men Regular Fit Printed Lapel Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/s/s/b/m-hs-57001-navy-voroxy-original-imahyypqmahrs2aa.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/s/s/b/m-hs-57001-navy-voroxy-original-imahyypqmahrs2aa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/9/p/i/xxl-hs-57001-navy-voroxy-original-imagzrvx6gmdn44f.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/h/i/w/xxl-hs-57001-navy-voroxy-original-imagzrvx6jdfbtns.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/k/s/8/m-hs-57001-navy-voroxy-original-imahyypqzn832nhq.jpeg?q=70&crop=false"
        ],
        subtitle: "Voroxy ",
        discountPrice: "499.00",
        mainPrice: "2099",
        description: "A stylish, solid spread collar casual shirt for men, perfect for any casual occasion."
      },      
      {
        id: 4,
        title: "Men Slim Fit Printed Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/kp1imq80hlty2aw/shirt/4/w/p/-original-imag3fznxpdrm3ey.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/kp1imq80hlty2aw/shirt/4/w/p/-original-imag3fznxpdrm3ey.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/kp1imq80hlty2aw/shirt/6/b/r/-original-imag3fznunadzu7w.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/kp1imq80hlty2aw/shirt/c/v/i/-original-imag3fznjsjkduzk.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/kp1imq80hlty2aw/shirt/p/j/2/-original-imag3fznxcdb4mhg.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/kp1imq80hlty2aw/shirt/5/6/s/-original-imag3fzntjzvbbhv.jpeg?q=70&crop=false"
        ],
        subtitle: "Apuesto ",
        discountPrice: "559.00",
        mainPrice: "1999",
        description: "Classic formal shirt with a spread collar and solid design, made from soft Ramraj cotton."
      },
      {
        id: 5,
        title: "Men Regular Fit Checkered Cut Away Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/t/o/t/xl-half-6line-aidan-paul-original-imahyg5gducp43jv.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/t/o/t/xl-half-6line-aidan-paul-original-imahyg5gducp43jv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/b/n/8/xl-half-6line-aidan-paul-original-imahyg5gs8rh85eu.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/f/a/1/xl-half-6line-aidan-paul-original-imahyg5gvdk9tey9.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/e/a/g/xl-half-6line-aidan-paul-original-imahyg5gxgpxheuw.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/w/j/z/xl-half-6line-aidan-paul-original-imahyg5grqp8g9pt.jpeg?q=70&crop=false"
        ],
        subtitle: "AIDAN PAUL ",
        discountPrice: "563.00",
        mainPrice: "1699",
        description: "Stylish casual shirt with a solid design and spread collar from WOXEN."
      },
      {
        id: 6,
        title: "Men Regular Fit Printed Spread Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/b/l/w/s-st101-vebnor-original-imah5f6cjmee5zen.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/b/l/w/s-st101-vebnor-original-imah5f6cjmee5zen.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/1/h/c/s-st101-vebnor-original-imah5f6c9htzfcv6.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/y/z/b/s-st101-vebnor-original-imah5f6cyf7nvnxq.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/i/3/s-st101-vebnor-original-imah5f6csbyyqkjp.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/6/8/s-st101-vebnor-original-imah5f6c9ztsya2h.jpeg?q=70&crop=false"
        ],
        subtitle: "VeBNoR ",
        discountPrice: "539.00",
        mainPrice: "1299",
        description: "Slim fit checkered shirt with a slim collar, perfect for casual wear."
      },
      {
        id: 7,
        title: "Men Regular Fit Solid Spread Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/h/9/c/xxl-st93-vebnor-original-imah8738yvcfc2j5.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/h/9/c/xxl-st93-vebnor-original-imah8738yvcfc2j5.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/g/7/f/xxl-st93-vebnor-original-imah8738ag58rf4p.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/h/l/c/xxl-st93-vebnor-original-imah8738wh6wuumk.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/y/y/j/xxl-st93-vebnor-original-imah87387ethhe2q.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/y/w/c/xxl-st93-vebnor-original-imah8738gurvjgyh.jpeg?q=70&crop=false"
        ],
        subtitle: "VeBNoR",
        discountPrice: "579.00",
        mainPrice: "1299",
        description: "Solid mandarin collar casual shirt for a trendy and modern look."
      },
      {
        id: 8,
        title: "Men Regular Fit Solid Spread Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/a/n/l/m-st84-vebnor-original-imah8738ekwvjqtx.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/a/n/l/m-st84-vebnor-original-imah8738ekwvjqtx.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/g/v/d/m-st84-vebnor-original-imah8738zketzz9j.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/8/b/c/m-st84-vebnor-original-imah8738hz68yg75.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/h/u/i/m-st84-vebnor-original-imah8738rapc5h3m.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/l/b/6/m-st84-vebnor-original-imah8738ezbxmgjx.jpeg?q=70&crop=false"
        ],
        subtitle: "VeBNoR",
        discountPrice: "569.00",
        mainPrice: "1299",
        description: "Solid spread collar casual shirt, perfect for a comfortable and trendy look."
      }
  ];
  