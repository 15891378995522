import React from "react";
import "./ProductPage.css";
import Productsectionforall from "./Productsectionforall";
import { poloshirt } from "./poloshirtdata";

const ProductPage = () => {
  return (
    <div className="product-page">
      {/* Hero Section */}
      <section className="polo">
        <div className="hero-content">
          <h1>"Effortlessly stylish and endlessly versatile – elevate your wardrobe with classic polo shirts!"</h1>
          <p>
          "Comfort meets sophistication – perfect for every occasion, from casual outings to smart events."</p>
          <button className="shop-now">Shop Now</button>
        </div>
      </section>

      <Productsectionforall products={poloshirt} />
    </div>
  );
};

export default ProductPage;
