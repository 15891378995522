import React, { useState, useEffect } from 'react';

const ShippingForm = ({ onNext }) => {
  const [addresses, setAddresses] = useState([]);
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);
  const [newAddress, setNewAddress] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
  });
  const [selectedAddress, setSelectedAddress] = useState(null);

  // Load addresses from local storage on component mount
  useEffect(() => {
    const storedAddresses = JSON.parse(localStorage.getItem('addresses')) || [];
    setAddresses(storedAddresses);
    const defaultAddress = storedAddresses.find(addr => addr.default);
    setSelectedAddress(defaultAddress?.id || null);
  }, []);

  // Save addresses to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('addresses', JSON.stringify(addresses));
  }, [addresses]);

  const handleNewAddressChange = (e) => {
    setNewAddress({ ...newAddress, [e.target.name]: e.target.value });
  };

  const handleAddNewAddress = (e) => {
    e.preventDefault();
    const newId = addresses.length + 1;
    const updatedAddresses = [...addresses, { ...newAddress, id: newId, default: false }];
    setAddresses(updatedAddresses);
    setShowNewAddressForm(false);
    setNewAddress({
      name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
    });
  };

  const handleSelectAddress = (id) => {
    setSelectedAddress(id);
    setAddresses(addresses.map(addr => ({ ...addr, default: addr.id === id })));
  };

  const handleRemoveAddress = (id) => {
    const updatedAddresses = addresses.filter(addr => addr.id !== id);
    setAddresses(updatedAddresses);
    if (selectedAddress === id) {
      setSelectedAddress(null);
    }
  };

  return (
    <div>
        <h2>Select Shipping Address</h2>
    <div className="shipping-section">
      
      {addresses.map(address => (
        <div key={address.id} className={`address-card ${selectedAddress === address.id ? 'selected' : ''}`}>
          <p><strong>{address.name}</strong></p>
          <p>{address.address}</p>
          <p>{address.city}, {address.state} - {address.zip}</p>
          <p>Phone: {address.phone}</p>
          <button 
            className="select-address-button" 
            onClick={() => handleSelectAddress(address.id)}
          >
            {selectedAddress === address.id ? 'Selected' : 'Select'}
          </button>
          <button 
            className="remove-address-button" 
            onClick={() => handleRemoveAddress(address.id)}
          >
            Remove
          </button>
        </div>
      ))}
      <button className="add-new-address-buttonn" onClick={() => setShowNewAddressForm(true)}>
        + Add New Address
      </button>

      {showNewAddressForm && (
        <form className="new-address-form" onSubmit={handleAddNewAddress}>
        <h3>Add New Address</h3>
        
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          onChange={handleNewAddressChange}
          required
          minLength="3"
          maxLength="50"
          title="Name should be between 3 to 50 characters"
        />
        
        <input
          type="text"
          name="address"
          placeholder="Address"
          onChange={handleNewAddressChange}
          required
          minLength="5"
          maxLength="100"
          title="Address should be between 5 to 100 characters"
        />
        
        <input
          type="text"
          name="city"
          placeholder="City"
          onChange={handleNewAddressChange}
          required
          minLength="2"
          maxLength="50"
          pattern="[a-zA-Z\s]+"
          title="City name should only contain letters"
        />
        
        <input
          type="text"
          name="state"
          placeholder="State"
          onChange={handleNewAddressChange}
          required
          minLength="2"
          maxLength="50"
          pattern="[a-zA-Z\s]+"
          title="State name should only contain letters"
        />
        
        <input
          type="text"
          name="zip"
          placeholder="ZIP Code"
          onChange={handleNewAddressChange}
          required
          pattern="\d{5}(-\d{4})?"
          title="ZIP Code should be a 5-digit number or 9-digit format (e.g., 12345 or 12345-6789)"
        />
        
        <input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          onChange={handleNewAddressChange}
          required
          pattern="^\d{10}$"
          title="Phone number should be exactly 10 digits"
        />
        
        <button type="submit" className="btn">Save Address</button>
      </form>
      
      )}

      <button 
        className="continue-buttonn " 
        onClick={onNext} 
        disabled={!selectedAddress}
      >
        Continue to Payment
      </button>
    </div></div>
  );
};

export default ShippingForm;
