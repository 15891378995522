import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ProductPage.css";
import SimilerProduct from './SimilerProduct';
import Sizechart from "./Sizechart";
import Magnify from "./Magnify";

export const calculateDiscount = (mainPrice, discountPrice) => {
  const discount = ((mainPrice - discountPrice) / mainPrice) * 100;
  return `${Math.round(discount)}% Off`;
};
const Productpage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const product = location.state;

  const [selectedSize, setSelectedSize] = useState("");
  const [mainImage, setMainImage] = useState(product?.images?.[0] || "");
  const [isInWatchlist, setIsInWatchlist] = useState(false);

  useEffect(() => {
    if (product?.id) {
      const watchlistItems = JSON.parse(localStorage.getItem("watchlistItems")) || [];
      const isProductInWatchlist = watchlistItems.some((item) => item.id === product.id);
      setIsInWatchlist(isProductInWatchlist);
    }
  }, [product]); // Runs whenever the 'product' prop changes

  // Function to handle Add to Cart
  const addToCart = () => {
    if (!selectedSize) {
      alert("Please select a size");
      return;
    }

    const existingCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    const existingProductIndex = existingCartItems.findIndex(
      (item) => item.id === product.id && item.size === selectedSize
    );

    if (existingProductIndex !== -1) {
      existingCartItems[existingProductIndex].quantity += 1;
    } else {
      existingCartItems.push({ ...product, size: selectedSize, quantity: 1 });
    }

    localStorage.setItem("cartItems", JSON.stringify(existingCartItems));
    alert("Added to cart");
  };

  // Function to handle Buy Now
  const buyNow = () => {
    if (!selectedSize) {
      alert("Please select a size");
      return;
    }

    const existingCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    existingCartItems.push({ ...product, size: selectedSize, quantity: 1 });
    localStorage.setItem("cartItems", JSON.stringify(existingCartItems));
    navigate("/Checkout");
  };

  // Function to handle Add to Watchlist
  const addToWatchlist = () => {
    const existingWatchlistItems = JSON.parse(localStorage.getItem("watchlistItems")) || [];
    if (!existingWatchlistItems.some((item) => item.id === product.id)) {
      existingWatchlistItems.push(product);
      localStorage.setItem("watchlistItems", JSON.stringify(existingWatchlistItems));
      setIsInWatchlist(true);
    } else {
      alert("This product is already in your watchlist");
    }
  };

  // If product doesn't exist, show an error page
  if (!product) {
    return (
      <div className="error-page">
        <h1>Product not found!</h1>
        <button onClick={() => navigate("/")}>Go Back to Products</button>
      </div>
    );
  }

  return (
    <div>
      <div className="product-container">
        
        <div className="small-images">
          {product.images && product.images.length > 0 ? (
            product.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                className="small-image"
                onClick={() => setMainImage(image)}
              />
            ))
          ) : (
            <p>No additional images available</p>
          )}
        </div>
        <div className="product-images">
          <img
            src={mainImage}
            alt={product.title}
            className="main-image"
          />
        </div>
        <div className="product-details">
          <h1>{product.title}</h1>
          <h2 className="sub-head">{product.subtitle}</h2>
          <div className="price-sectionn">
            <span className="discount-pricee">Rs.{product.discountPrice}</span>
            <span className="original-pricee">Rs.{product.mainPrice}</span>
            <span className="discount">{calculateDiscount(product.mainPrice, product.discountPrice)}</span>
          </div>

          <div className="product-description">
            <h3>Description</h3>
            <p>{product.description}</p>
          </div>

          <div className="size-selection">
            <label htmlFor="size">Select Size:</label>
            <select
              id="size"
              value={selectedSize}
              onChange={(e) => setSelectedSize(e.target.value)}
            >
              <option value="">--Select Size--</option>
              <option value="S">Small</option>
              <option value="M">Medium</option>
              <option value="L">Large</option>
              <option value="XL">X-Large</option>
              <option value="XXL">XX-Large</option>
            </select>
          </div>
          <Sizechart />
          <div className="button-group">
            <button className="cart-button add-to-cart-btn" onClick={addToCart}>
              Add To Cart
            </button>
            <button className="cart-button buy-now-btn" onClick={buyNow}>
              Buy Now
            </button>
            <button
        className="cart-button watchlist-btn"
        onClick={addToWatchlist}
      >
        <i
          className={`fa fa-heart ${isInWatchlist ? "text-danger" : "text-muted"}`}
          style={{ marginRight: "8px", fontSize: "20px" , color:"red" }}
        />
        {isInWatchlist ? "Added" : "Add"}
      </button>
          </div>
        </div>
      </div>
      <div className="charge-box-container">
      <div className="charge-box">
        <div className="charge-box-item">
          <img 
            src="https://www.beyoung.in/desktop/images/product-details-2/packed.png" 
            alt="Feature Image 1" 
            className="charge-box-image" 
          />
          <p className="charge-box-text">Packed with safety</p>
        </div>
        <div className="charge-box-item">
          <img 
            src="https://www.kindpng.com/picc/m/204-2041500_bang-satisfied-customer-happy-customer-icon-hd-png.png" 
            alt="Feature Image 2" 
            className="charge-box-image" 
          />
          <p className="charge-box-text">3M+ Happy Customers</p>
        </div>
        <div className="charge-box-item">
          <img 
            src="https://cdn3d.iconscout.com/3d/premium/thumb/brand-badge-3d-icon-download-in-png-blend-fbx-gltf-file-formats--like-logo-quality-user-experience-customer-rating-marketing-pack-network-communication-icons-9056927.png?f=webp" 
            alt="Feature Image 3" 
            className="charge-box-image" 
          />
          <p className="charge-box-text">Top Brands</p>
        </div>
        <div className="charge-box-item">
          <img 
            src="https://img.freepik.com/premium-vector/best-quality-premium-badge-emblem_691616-171.jpg" 
            alt="Feature Image 4" 
            className="charge-box-image" 
          />
          <p className="charge-box-text">Best Quality</p>
        </div>
      </div>
    </div>
      <h1 style={{ marginTop: "2%", color: "white", backgroundColor: "#444", width: "100%", textAlign: "center" }}>Similar Products</h1>
      <SimilerProduct />
    </div>
  );
};

export default Productpage;
