import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import CartIcon from './carticon';
import { useNavigate } from 'react-router-dom';
import { handleSuccess } from './utils';

const Header = () => {
  const [isNavActive, setIsNavActive] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(null); // Track dropdown state
  const location = useLocation(); // Access the current location
  const navRef = useRef(null); // Reference to the navigation menu
  const navigate = useNavigate();

  // Toggle navigation menu visibility
  const toggleNav = () => {
    setIsNavActive(!isNavActive);
  };

  // Handle dropdown toggle
  const toggleDropdown = (index) => {
    setDropdownActive(dropdownActive === index ? null : index);
  };

  // Close the menu if the user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsNavActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
   const handleLogout = (e) => {
          localStorage.removeItem('token');
          localStorage.removeItem('loggedInUser');
          handleSuccess('User Loggedout');
          setTimeout(() => {
              navigate('/Login');
          }, 100)
      }

  return (
    <header className="header">
      <div className="containerr">
        <nav className="navbar" ref={navRef}>
          <div className="menu-icon" onClick={toggleNav}>
            <i className="fas fa-bars"></i>
          </div>
          <Link to="/" className="logo"><img src='https://i.imgur.com/xJJgBF8.jpeg'/><p>BLAS BLUSH</p>  </Link>
          <ul className={`nav-links ${isNavActive ? 'active' : ''}`}>
  <li className={`dropdown ${location.pathname === '/' ? 'active' : ''}`}>
    <NavLink to="/">Home</NavLink>
  </li>
  <li className={`dropdown ${location.pathname === '/TrendingClothes' ? 'active' : ''}`}>
    <NavLink to="#">Groom</NavLink>
    <ul className="dropdown-menu">
      <li><NavLink to="/TrendingClothes">Trend</NavLink></li>
      <li><NavLink to="/Mirror">Mirror</NavLink></li>
    </ul>
  </li>
  <li className={`dropdown ${location.pathname === '/Aboutsection' ? 'active' : ''}`}>
    <Link to="#">Company</Link>
    <ul className="dropdown-menu">
      <li><NavLink to="/Aboutsection">About</NavLink></li>
      <li><NavLink to="#footer">Contact</NavLink></li>
      <li><NavLink to="#">Career</NavLink></li>
    </ul>
  </li>
  <li className={`dropdown ${location.pathname === '/Oldfashion' ? 'active' : ''}`}>
    <NavLink to="#">Products</NavLink>
    <ul className="dropdown-menu">
      <li><NavLink to="Oldfashion">Old Fashion</NavLink></li>
      <li><NavLink to="Formal">Formal Shirt</NavLink></li>
      <li><NavLink to="Poloshirt">Polo Shirts</NavLink></li>
      <li><NavLink to="Polotshirt">Polo T Shirt</NavLink></li>
    </ul>
  </li>
</ul>

          <div className='usercart'>
          <div className="user-section">
            <a className="user-icon">
              <span className="username"><i className="fa">&#xf007;</i></span>
            </a>
            <div className="user-dropdown">
              <ul>
                <li><Link to="Login"><i className="fa">&#xf007;</i> My Profile</Link></li>
                <li><Link to="#"><i className="fas fa-shopping-bag"></i> Orders</Link></li>
                <li><Link to="Watchlist"><i className="fas fa-heart"></i> Wishlist</Link></li>
                <li><Link to="#"><i className="fas fa-sign-out-alt" onClick={handleLogout}></i> Logout</Link></li>
              </ul>
            </div>
          </div>
          <div className='cartIcon'><CartIcon /></div></div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
