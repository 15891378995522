import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"; // Import motion from framer-motion

const CategorySection = () => {
  return (
    <section className="category-section">
      <div className="category-section-inner">
        <motion.div
          className="category-section-header"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <p className="category-caption">Your Style, Your Choice</p>
          <h2 className="category-title">Classify Your Choice</h2>
        </motion.div>

        <div className="category-collections">
          {/* Formal Shirts */}
          <motion.div
            className="collection-item"
            initial={{ opacity: 0, x:+100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          > <Link to="Formal">
            <a href="/collections/formal-shirts">
              <div className="collection-media">
                
                <img
                  src="https://myraymond.com/cdn/shop/collections/shirt_08d8c6cc-594d-43d7-a68d-9208779c22f4.webp?v=1711690156"
                  alt="Formal Shirts"
                />
              </div>
              <div className="collection-text">
                <p>Formal Shirts</p>
              </div>
            </a></Link>
          </motion.div>

          {/* Casual Shirts */}
          <motion.div
            className="collection-item"
            initial={{ opacity: 0, x: +100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <a href="/collections/casual-shirts">
              <div className="collection-media">
                <img
                  src="https://rukminim2.flixcart.com/image/612/612/xif0q/shirt/v/c/9/xl-st2-vebnor-original-imah7n3rsttgzw8s.jpeg?q=70"
                  alt="Casual Shirts"
                />
              </div>
              <div className="collection-text">
                <p>Casual Shirts</p>
              </div>
            </a>
          </motion.div>

          {/* Trousers & Chinos */}
          <motion.div
            className="collection-item"
            initial={{ opacity: 0, x: +100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <a href="/collections/trousers-chinos">
              <div className="collection-media">
                <img
                  src="https://myraymond.com/cdn/shop/collections/CMTR11850-K7_20_282_29.webp?v=1710856267"
                  alt="Trousers & Chinos"
                />
              </div>
              <div className="collection-text">
                <p>Trousers & Chinos</p>
              </div>
            </a>
          </motion.div>

          {/* T-Shirts & Polo */}
          <motion.div
            className="collection-item"
            initial={{ opacity: 0, x: +100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <a href="/collections/t-shirts-polos">
              <div className="collection-media">
                <img
                  src="https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/o/f/i/-original-imah23cjdwngg4yh.jpeg?q=70"
                  alt="T-Shirts & Polo"
                />
              </div>
              <div className="collection-text">
                <p>T-Shirts & Polo</p>
              </div>
            </a>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default CategorySection;
