import React, { useState } from 'react';

const PaymentForm = ({ onNext, onBack }) => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  });
  const [upiId, setUpiId] = useState('');
  const [isCardValid, setIsCardValid] = useState(true);
  const [isUpiValid, setIsUpiValid] = useState(true);

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleCardDetailsChange = (e) => {
    setCardDetails({ ...cardDetails, [e.target.name]: e.target.value });
  };

  const handleUpiIdChange = (e) => {
    setUpiId(e.target.value);
  };

  const validateCardDetails = () => {
    const { cardNumber, expiryDate, cvv } = cardDetails;
    const isValid = cardNumber.length === 16 && expiryDate && cvv.length === 3;
    setIsCardValid(isValid);
    return isValid;
  };

  const validateUpiId = () => {
    const isValid = upiId.includes('@');
    setIsUpiValid(isValid);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (paymentMethod === 'creditCard' && validateCardDetails()) {
      console.log('Payment Details:', paymentMethod, cardDetails);
      onNext();
    } else if (paymentMethod === 'upi' && validateUpiId()) {
      console.log('Payment Details:', paymentMethod, upiId);
      onNext();
    }
  };

  return (
    <div className="payment-section">
      <h2>Select Payment Method</h2>
      <div className="payment-container">
        {/* Payment Options */}
        <div className="payment-methods">
          <label className="payment-option">
            <input
              type="radio"
              name="paymentMethod"
              value="creditCard"
              checked={paymentMethod === 'creditCard'}
              onChange={handlePaymentMethodChange}
            />
            Credit/Debit Card
          </label>
          <label className="payment-option">
            <input
              type="radio"
              name="paymentMethod"
              value="upi"
              checked={paymentMethod === 'upi'}
              onChange={handlePaymentMethodChange}
            />
            UPI
          </label>
          <label className="payment-option">
            <input
              type="radio"
              name="paymentMethod"
              value="netBanking"
              checked={paymentMethod === 'netBanking'}
              onChange={handlePaymentMethodChange}
            />
            Net Banking
          </label>
          <label className="payment-option">
            <input
              type="radio"
              name="paymentMethod"
              value="cash on delivary"
              checked={paymentMethod === 'cash on delivary'}
              onChange={handlePaymentMethodChange}
            />
            Cash on delivary
          </label>
        </div>

        {/* Card Details */}
        {paymentMethod === 'creditCard' && (
          <div className="card-details">
            <h3>Enter Card Details</h3>
            <input
              type="text"
              name="cardNumber"
              placeholder="Card Number"
              value={cardDetails.cardNumber}
              onChange={handleCardDetailsChange}
              maxLength="16"
              required
            />
            <input
              type="text"
              name="expiryDate"
              placeholder="Expiry Date (MM/YY)"
              value={cardDetails.expiryDate}
              onChange={handleCardDetailsChange}
              required
            />
            <input
              type="text"
              name="cvv"
              placeholder="CVV"
              value={cardDetails.cvv}
              onChange={handleCardDetailsChange}
              maxLength="3"
              required
            />
            {!isCardValid && <p className="error-message">Please enter valid card details.</p>}
          </div>
        )}

        {/* UPI Details */}
        {paymentMethod === 'upi' && (
          <div className="upi-details">
            <h3>Enter UPI ID</h3>
            <input
              type="text"
              name="upiId"
              placeholder="Your UPI ID (e.g., user@bank)"
              value={upiId}
              onChange={handleUpiIdChange}
              required
            />
            {!isUpiValid && <p className="error-message">Please enter a valid UPI ID.</p>}
          </div>
        )}
      </div>

      {/* Buttons */}
      <div className="payment-buttons">
        {paymentMethod && (
          <button
            className="continue-button"
            onClick={handleSubmit}
            disabled={!paymentMethod}
          >
            Proceed
          </button>
        )}
        <button
          className="back-button"
          onClick={onBack}
        >
          Back to Review Order
        </button>
      </div>
    </div>
  );
};

export default PaymentForm;
