import React from 'react';

const ProgressBar = ({ step, onStepClick }) => {
  const handleStepClick = (stepNumber) => {
    if (onStepClick) {
      onStepClick(stepNumber); // Call the onStepClick callback with the clicked step number
    }
  };

  return (
    <div className="progress-bar">
      <div
        className={`step ${step >= 2 ? 'active' : ''}`}
        onClick={() => handleStepClick(2)}
        style={{ cursor: step >= 2 ? 'pointer' : 'not-allowed' }}
      >
        Shipping
      </div>
      <div
        className={`step ${step >= 3 ? 'active' : ''}`}
        onClick={() => handleStepClick(3)}
        style={{ cursor: step >= 3 ? 'pointer' : 'not-allowed' }}
      >
        Review Order
      </div>
      <div
        className={`step ${step >= 4 ? 'active' : ''}`}
        onClick={() => handleStepClick(4)}
        style={{ cursor: step >= 4 ? 'pointer' : 'not-allowed' }}
      >
        Payment
      </div>
    </div>
  );
};

export default ProgressBar;
