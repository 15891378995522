import React from "react";
import "./ProductPage.css";
import Productsectionforall from "./Productsectionforall";
import { polotshirt } from "./polotshirtdata";

const ProductPage = () => {
  return (
    <div className="product-page">
      {/* Hero Section */}
      <section className="polotshirt">
        <div className="hero-content">
          <h1>"Stay cool, stay classic – redefine your style with our premium polo t-shirts!"</h1>
          <p>"Where comfort meets class – perfect for everyday wear and beyond."</p>
          <button className="shop-now">Shop Now</button>
        </div>
      </section>

     <Productsectionforall products={polotshirt}/>
    </div>
  );
};

export default ProductPage;
