
const productdata = [
    {
        id: 4,
        title: "Men Regular Fit Solid Spread Collar Formal Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "Ramraj Cotton",
        discountPrice: "658.00",
        mainPrice: "999",
        description: "Classic formal shirt with a spread collar and solid design, made from soft Ramraj cotton."
      },
      {
        id: 5,
        title: "Men Regular Fit Solid Spread Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "WOXEN",
        discountPrice: "755.00",
        mainPrice: "2099",
        description: "Stylish casual shirt with a solid design and spread collar from WOXEN."
      },
      {
        id: 6,
        title: "Men Slim Fit Checkered Slim Collar Casual Shirt#JustHere",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "Tanip",
        discountPrice: "519.00",
        mainPrice: "1399",
        description: "Slim fit checkered shirt with a slim collar, perfect for casual wear."
      },
      {
        id: 7,
        title: "Men Regular Fit Solid Mandarin Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "RAHUL LOOK",
        discountPrice: "669.00",
        mainPrice: "999",
        description: "Solid mandarin collar casual shirt for a trendy and modern look."
      }
  ];
  export default productdata;