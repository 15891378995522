import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function OrderSummary({ totalMRP, totalDiscount, shippingCost, totalAmount, isCartEmpty }) {
  return (
    <div className="order-summary-container">
      <p>
        <div>Price:</div> <div>₹{totalMRP.toFixed(2)}</div>
      </p>
      <p>
        <div>Discount:</div> <div>₹-{totalDiscount.toFixed(2)}</div>
      </p>
      <p>
        <div>Shipping Cost:</div>
        <div>{isCartEmpty ? "₹0.00" : shippingCost === 0 ? "Free" : `₹${shippingCost.toFixed(2)}`}</div>
      </p>
      <h3>
        <div>Total Amount:</div> <div>₹{totalAmount.toFixed(2)}</div>
      </h3>
      <h1>₹{totalAmount.toFixed(2)}</h1>
    </div>
  );
}

function ParentComponent() {
  const [cartItems, setCartItems] = useState([]);
  const [totalMRP, setTotalMRP] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [shippingCost, setShippingCost] = useState(0); // Dynamic shipping cost
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    // Retrieve cart items from localStorage and set the state
    const cartData = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(cartData);
    calculateTotals(cartData);
  }, []);

  const calculateTotals = (cartData) => {
    let calculatedMRP = 0;
    let calculatedDiscount = 0;

    cartData.forEach((item) => {
      // Ensure mainPrice and discountPrice are strings, then remove any 'Rs.' prefix and convert to float
      const mainPrice = item.mainPrice
        ? parseFloat(item.mainPrice.toString().replace("Rs.", "").replace(",", ""))
        : 0;
      const discountPrice = item.discountPrice
        ? parseFloat(item.discountPrice.toString().replace("Rs.", "").replace(",", ""))
        : mainPrice;

      calculatedMRP += mainPrice * item.quantity;
      calculatedDiscount += (mainPrice - discountPrice) * item.quantity;
    });

    // Determine shipping cost based on total MRP and cart state
    const isCartEmpty = cartData.length === 0;
    const newShippingCost = isCartEmpty ? 0 : calculatedMRP - calculatedDiscount >= 500 ? 0 : 50;

    setTotalMRP(calculatedMRP);
    setTotalDiscount(calculatedDiscount);
    setShippingCost(newShippingCost);

    // Calculate total amount
    const calculatedTotalAmount = calculatedMRP - calculatedDiscount + newShippingCost;
    setTotalAmount(calculatedTotalAmount);
  };

  const navigate = useNavigate();
  const handleProceed = () => {
    navigate("/Fullcheckout");
  };

  const isCartEmpty = cartItems.length === 0;

  return (
    <div className="summarycontainor">
      <h3>Order Summary</h3>
      <OrderSummary
        totalMRP={totalMRP}
        totalDiscount={totalDiscount}
        shippingCost={shippingCost}
        totalAmount={totalAmount}
        isCartEmpty={isCartEmpty}
      />

      {/* Proceed button below the summary */}
      <button
        onClick={handleProceed}
        className="proceed-button"
        disabled={isCartEmpty}
      >
        Proceed
      </button>
    </div>
  );
}

export default ParentComponent;
