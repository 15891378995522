import React from "react";
import Productsectionforall from "./Productsectionforall";
import productdata from "./SimilerProductdata";

const ProductSection = () => {

  return (
    <div className="product-section">
     <Productsectionforall products={productdata}/>
    </div>
  );
};

export default ProductSection;
