import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CartItems from "./CartItems";
import Watchlist from './Watchlist';
import Ordersummary from "./Ordersummary";
import './checkout.css';

    const App = () => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Load cart items from localStorage
    const savedItems = localStorage.getItem('cartItems');
    if (savedItems) setCartItems(JSON.parse(savedItems));
  }, []);

  const removeFromCart = (name) => {
    const updatedCart = cartItems.filter(item => item.name !== name);
    setCartItems(updatedCart);
    localStorage.setItem('cartItems', JSON.stringify(updatedCart));
  };

  return (
    <div>
     <div className='checkout-header'>
     <Link to="/" className='checkout-logo'><img src='https://i.imgur.com/xJJgBF8.jpeg'/> BLAS BLUSH</Link>
    <div className='watchlist'> <Link to="Watchlist"><i className="fas fa-heart"></i></Link>
    </div></div>
      <div  className="checkoutcontainer">
      <CartItems/>
      <Ordersummary/>
      </div>
      
      
    </div>
  );
};

export default App;
