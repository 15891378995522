import React from "react";
import Productsectionforall from "./Productsectionforall";
import { Producdata } from "./Productdata";

const ProductSection = () => {

  return (
    <div className="product-section">
     <Productsectionforall products={Producdata}/>
    </div>
  );
};

export default ProductSection;
