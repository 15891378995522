import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import ShippingForm from './ShippingForm';
import Paymentsection from './Paymentsection';
import Ordersummary from './Ordersummary';
import CartItems from './CartItems';

const CheckoutPage = () => {
  const [step, setStep] = useState(2); // Start at Shipping (step 2)

  const handleNext = () => {
    if (step < 4) {
      setStep(step + 1); // Move to next step
    }
  };

  const handlePrevious = () => {
    if (step > 2) {
      setStep(step - 1); // Go back to previous step
    }
  };

  return (
    <div className="checkout-fullpage">
      <div className="checkout-header">
        <Link to="/" className="checkout-logo">BLAS BLUSH</Link>
        <div className="watchlist">
          <Link to="Watchlist"><i className="fas fa-heart"></i></Link>
        </div>
      </div>

      <ProgressBar step={step} />

      <div className="content">
        {step === 2 && <ShippingForm onNext={handleNext} />}

        {step === 3 && (
          <div>
            <h2>Review Your Order</h2>
            <CartItems />
            <button className='buttonn'  onClick={handlePrevious}>Back to Shipping</button>
            <button className='buttonn' onClick={handleNext}>Proceed to Payment</button>
            
          </div>
        )}

        {step === 4 && <Paymentsection onNext={handleNext} onBack={handlePrevious} />}

        <Ordersummary />
      </div>
    </div>
  );
};

export default CheckoutPage;
