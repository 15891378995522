// ProductsData.js
export const formaldata = [
    {
      id: 1,
      title: "Park Avenue Blue Structure Slim Fit Cotton Formal Shirt",
      image: "https://myraymond.com/cdn/shop/files/PMSX17194-P7-1.jpg?v=1716965794",
      images: [
        "https://myraymond.com/cdn/shop/files/PMSX17194-P7-1.jpg?v=1716965794",
        "myraymond.com/cdn/shop/files/PMSX17194-P7-2.jpg?v=1716965795&width=550 550w,//myraymond.com/cdn/shop/files/PMSX17194-P7-2.jpg?v=1716965795 640w",
        "https://myraymond.com/cdn/shop/files/PMSX17194-P7-2.jpg?v=1716965795",
        "https://myraymond.com/cdn/shop/files/PMSX17194-P7-6.jpg?v=1716965794",
       "https://myraymond.com/cdn/shop/files/PMSX17194-P7-7.jpg?v=1716965795"
      ],
      subtitle: "Brown Mandarin",
      discountPrice: "1349.00",
      mainPrice: "2999.00",
      description: "Comfortable and stylish regular fit jeans with a light grey shade. Perfect for casual outings."
    },
    {
      id: 2,
      title: "Park Avenue Green Structure Slim Fit Full Sleeve Cotton Shirt",
      image: "https://myraymond.com/cdn/shop/files/PMSX17435-N2-1.jpg?v=1719766692",
      images: [
        "https://myraymond.com/cdn/shop/files/PMSX17435-N2-1.jpg?v=1719766692",
        "https://myraymond.com/cdn/shop/files/PMSX17435-N2-2.jpg?v=1719766692",
        "https://myraymond.com/cdn/shop/files/PMSX17435-N2-3.jpg?v=1719766692",
        "https://myraymond.com/cdn/shop/files/PMSX17435-N2-6.jpg?v=1719766693"
      ],
      subtitle: " Grey Utility",
      discountPrice: "1799.00",
      mainPrice: "3999.00",
      description: "Trendy full sleeve sweatshirt with printed design, perfect for casual wear during colder seasons."
    },
    {
        id: 3,
        title: "Men Black Slim Fit Print Cotton Shirts",
        image: "https://myraymond.com/cdn/shop/products/PMSX16912-K9_20_281_29.webp?v=1709222986&width=1100",
        images: [
          "https://myraymond.com/cdn/shop/products/PMSX16912-K9_20_281_29.webp?v=1709222986&width=1100",
          "https://myraymond.com/cdn/shop/products/PMSX16912-K9_20_282_29.webp?v=1709222986&width=1100",
          "https://myraymond.com/cdn/shop/products/PMSX16912-K9_20_284_29.webp?v=1709222986&width=1100",
          "https://myraymond.com/cdn/shop/products/PMSX16912-K9_20_286_29.webp?v=1709222986&width=1100"
        ],
        subtitle: "Maan International",
        discountPrice: "1034.00",
        mainPrice: "2299.00",
        description: "A stylish, solid spread collar casual shirt for men, perfect for any casual occasion."
      },      
      {
        id: 4,
        title: "Men Regular Fit Solid Spread Collar Formal Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/128/128/xif0q/shirt/5/h/y/50-whitediamond1pf-ramraj-cotton-original-imagyt4cs4fafuyy.jpeg?q=70&crop=false",
         ],
        subtitle: "Ramraj Cotton",
        discountPrice: "658.00",
        mainPrice: "999",
        description: "Classic formal shirt with a spread collar and solid design, made from soft Ramraj cotton."
      },
      {
        id: 5,
        title: "Men Slim Fit Solid Spread Collar Formal Shirt",
        image: "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/m/q/3/m-r-peacock-blue-stoneberg-original-imah5f7wrynbprrd.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/m/q/3/m-r-peacock-blue-stoneberg-original-imah5f7wrynbprrd.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/k/k/x/m-r-peacock-blue-stoneberg-original-imah5f7wwvpucfyr.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/u/5/f/m-r-peacock-blue-stoneberg-original-imah5f7w2guyxhum.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/416/416/xif0q/shirt/w/v/j/m-r-peacock-blue-stoneberg-original-imah5f7wtchcpbca.jpeg?q=70&crop=false",
        ],
        subtitle: "STONEBERG ",
        discountPrice: "569.00",
        mainPrice: "1799",
        description: "Stylish casual shirt with a solid design and spread collar from WOXEN."
      },
      {
        id: 6,
        title: "Men Regular Fit Solid Spread Collar Formal Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/w/q/l-plain-navy-shirt-nofilter-original-imah2cum8fzqdfhe.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/w/q/l-plain-navy-shirt-nofilter-original-imah2cum8fzqdfhe.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/x/t/n/l-plain-navy-shirt-nofilter-original-imah2cumtrgzape7.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/n/m/q/l-plain-navy-shirt-nofilter-original-imah2cumpky9gcuy.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/r/e/g/l-plain-navy-shirt-nofilter-original-imah2cumhagfxwhv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/e/l/l/l-plain-navy-shirt-nofilter-original-imah2cumvx3sbqgr.jpeg?q=70&crop=false"
        ],
        subtitle: "Nofilter ",
        discountPrice: "549.00",
        mainPrice: "1399",
        description: "Slim fit checkered shirt with a slim collar, perfect for casual wear."
      },
      {
        id: 7,
        title: "Men Regular Fit Solid Spread Collar Formal Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/w/c/l-mens-shirt-ketsalya-original-imah383hukzp522v.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/w/c/l-mens-shirt-ketsalya-original-imah383hukzp522v.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/o/q/l-mens-shirt-ketsalya-original-imah383hfdtgbxnj.jpeg?q=70&crop=false",
        ],
        subtitle: "vertaliindustries ",
        discountPrice: "569.00",
        mainPrice: "1199",
        description: "Solid mandarin collar casual shirt for a trendy and modern look."
      },
      {
        id: 8,
        title: "Men Regular Fit Solid Spread Collar Formal Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/l/n/l-1-vertaliindustries-original-imahfms6regynuek.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/l/n/l-1-vertaliindustries-original-imahfms6regynuek.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/n/b/l-1-vertaliindustries-original-imahfms6y5qsazhw.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/d/z/d/l-1-vertaliindustries-original-imahfms6hhycarjj.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/z/s/l-1-vertaliindustries-original-imahfms6zd2rs5yq.jpeg?q=70&crop=false",
          ],
        subtitle: "vertaliindustries",
        discountPrice: "599.00",
        mainPrice: "1099",
        description: "Solid spread collar casual shirt, perfect for a comfortable and trendy look."
      }
  ];
  