import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion"; // Import motion from framer-motion

const CollectionsSection = () => {
  return (
    <section className="collections-section">
      <div className="container">
        <h1>Our Collections</h1>
        <div className="collection-grid">
          {/* Product 1 */}
          <motion.div 
            className="collection-item"
            initial={{ x: "-100%" }} // Start off-screen (to the left)
            whileInView={{ x: 0 }} // Slide into its normal position (0 position)
            transition={{ type: "spring", stiffness: 100, damping: 45 }} // Spring-based animation for smooth effect
          > 
          <Link to="Oldfashion">
            <img
              src="https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/crocodile_green_geometric_print_half_sleeve_mens_shirt_base_700x933.jpg"
              alt="Product 1"
            />
            <div className="text-overlay">
             
                <h2>Old Fashion</h2>
             
            </div> </Link>
          </motion.div>

          {/* Product 2 */}
          <motion.div
            className="collection-item"
            initial={{ x: "-100%" }}
            whileInView={{ x: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 45 }}
          >
            <Link to="Formal">
              <img
                src="https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/m/q/r/xl-formal-shirt-metronaut-original-imah3g7mqnjcfytf.jpeg?q=70&crop=false"
                alt="Product 1"
              />
              <div className="text-overlay">
                <h2>Formal Shirt</h2>
              </div>
            </Link>
          </motion.div>

          {/* Product 3 */}
          <motion.div
            className="collection-item"
            initial={{ x: "-100%" }}
            whileInView={{ x: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 45 }}
          ><Link to="Poloshirt">
            <img
              src="https://rukminim2.flixcart.com/image/612/612/xif0q/shirt/d/o/w/xxl-st43-vebnor-original-imah7gfwwdmcb7fk.jpeg?q=70"
              alt="Product 2"
            />
            <div className="text-overlay">
              
                <h2>Polo Shirt</h2>
            
            </div>  </Link>
          </motion.div>

          {/* Product 4 */}
          <motion.div
            className="collection-item"
            initial={{ x: "-100%" }}
            whileInView={{ x: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 45 }}
          ><Link to="polotshirt">
            <img
              src="https://rukminim2.flixcart.com/image/612/612/xif0q/t-shirt/u/w/r/s-ts36-vebnor-original-imah74ddfgtkzfhe.jpeg?q=70"
              alt="Product 3"
            />
            <div className="text-overlay">
              
                <h2>Polo T-shirt</h2>
           
            </div>   </Link>
          </motion.div>

          {/* Add more products as needed */}
        </div>
      </div>
    </section>
  );
};

export default CollectionsSection;
