import React from "react";
import "./ProductPage.css";
import Productsectionforall from "./Productsectionforall";
import { formaldata } from "./Formaldata";

const ProductPage = () => {
  return (
    <div className="product-page">
      {/* Hero Section */}
      <section className="Formal">
        <div className="hero-content">
          <h1>Class Meets Comfort – Perfect Formal Attire for Any Occasion.</h1>
          <p>Redefine Your Professional Look with Style.</p>
          <button className="shop-now">Shop Now</button>
        </div>
      </section>
      <Productsectionforall products={formaldata}/>
    </div>
  );
};

export default ProductPage;
