import React, { useEffect, useRef, useState } from "react";
import "./Slider.css";

const Slider = () => {
  const desktopImages = [
    "https://i.imgur.com/UFqXknC.png",
    "https://i.imgur.com/g44NNnQ.jpeg",
    "https://myraymond.com/cdn/shop/files/Banner_2_d3218c2c-b03b-4a89-a43b-1fc7bcbd2451.png?v=1724996942",
    "https://www.snitch.co.in/cdn/shop/files/3_WebBanner_1920x1080_1_1400x.jpg?v=1725093880",
  ];

  const mobileImages = [
    "https://myraymond.com/cdn/shop/files/Mobile_Banner2.png?v=1734932586&width=375",
    "https://myraymond.com/cdn/shop/files/Mobile_Banner_3_119e57cc-8fb1-48e8-89db-5e7316f4b8f0.jpg?v=1728282294&width=375",
    "https://myraymond.com/cdn/shop/files/Mobile_Website_Banner_2_81eb5947-6ed4-4e98-ad4b-6bedd26f3e7f.png?v=1736398120&width=375",
   
  ];

  const [images, setImages] = useState(desktopImages); // Default to desktop images
  const [currentIndex, setCurrentIndex] = useState(0); // Start at the first image
  const totalImages = images.length;

  const sliderWrapperRef = useRef(null);

  const updateSlidePosition = () => {
    const offset = -currentIndex * 100;
    if (sliderWrapperRef.current) {
      sliderWrapperRef.current.style.transition = "transform 0.5s ease"; // Smooth sliding
      sliderWrapperRef.current.style.transform = `translateX(${offset}%)`;
    }
  };

  useEffect(() => {
    updateSlidePosition();
  }, [currentIndex]);

  const moveToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalImages);
  };

  const moveToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalImages) % totalImages);
  };

  useEffect(() => {
    const interval = setInterval(moveToNextSlide, 3000); // Auto transition every 3 seconds
    return () => clearInterval(interval);
  }, [totalImages]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 750) {
        setImages(mobileImages);
      } else {
        setImages(desktopImages);
      }
    };

    // Initial check
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="slider-container">
      <button className="slider-btn left-btn" onClick={moveToPrevSlide}>
        &lt;
      </button>
      <div className="slider-wrapper" ref={sliderWrapperRef}>
        {images.map((src, index) => (
          <img key={index} src={src} alt={`Slide ${index}`} />
        ))}
      </div>
      <button className="slider-btn right-btn" onClick={moveToNextSlide}>
        &gt;
      </button>
      <div className="slider-indicators">
        {images.map((_, index) => (
          <button
            key={index}
            className={`indicator ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Slider;
