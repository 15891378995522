import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TrendingClothes from "./components/TrendingClothes";
import ImageSlider from "./components/ImageSlider";
import CollectionSection from "./components/CollectionsSection";
import CategorySection from "./components/CategorySection";
import ProductSection from "./components/ProductSection";
import ProductPage from "./components/Productpage";
import Checkout from "./components/Checkout";
import Mirror from "./components/Mirror";
import ProductList from "./components/ProductList";
import Oldfashion from "./components/Oldfashion";
import Formal from "./components/Formal";
import Poloshirt from "./components/Poloshirt";
import Polotshirt from "./components/Polotshirt";
import Login from "./components/Login";
import StyleFinder from "./components/styleFinder";
import AboutSection from "./components/Aboutsection";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Watchlist from "./components/Watchlist";
import Fullcheckout from "./components/Fullcheckout";
import Signup from "./components/Signup";
// import Signup from "./components/Signup";

function App() {
  const location = useLocation();

  // Define conditions for pages with unique layouts
  const isSpecialPage =
    location.pathname === "/TrendingClothes" ||
    location.pathname === "/Productpage" ||
    location.pathname === "/Formal" ||
    location.pathname === "/Poloshirt"||
    location.pathname === "/Polotshirt"||
    location.pathname === "/Watchlist"||
    location.pathname === "/Checkout/Watchlist"||
    location.pathname === "/Fullcheckout/Watchlist"||
    location.pathname === "/Aboutsection"||
    location.pathname === "/Mirror";

  const isOldFashionPage = location.pathname === "/Oldfashion";
  const isCheckoutPage = location.pathname === "/Checkout"|| location.pathname === "/Fullcheckout"|| location.pathname === "/Signup" || location.pathname === "/Login";

  return (
    <div>
      {/* Show Header unless it's Checkout */}
      {!isCheckoutPage && <Header />}

      <Routes>
        <Route path="/TrendingClothes" element={<TrendingClothes />} />
        <Route path="/Mirror" element={<Mirror />} />
        <Route path="/Checkout" element={<Checkout />} />
        <Route path="/ProductList" element={<ProductList />} />
        <Route path="/Productpage" element={<ProductPage />} />
        <Route path="/Oldfashion" element={<Oldfashion />} />
        <Route path="/Poloshirt"  element={ <Poloshirt/>}/>
        <Route path="/polotshirt"  element={ <Polotshirt/>}/>
        <Route path = "/Formal" element= { <Formal/>} />
        <Route path="/Aboutsection" element ={<AboutSection/>}/>
        <Route path = "/Login" element= { <Login/>} />
        <Route path = "/Watchlist" element= { <Watchlist/>} />
        <Route path="/Fullcheckout" element={<Fullcheckout/>} />
        <Route path = "/Checkout/Watchlist" element= { <Watchlist/>} />
        <Route path = "/Fullcheckout/Watchlist" element= { <Watchlist/>} />
        <Route path = "/Signup" element= { <Signup/>} />

      </Routes>

      {/* Show additional sections only when not in special pages */}
      {!isSpecialPage && !isCheckoutPage && !isOldFashionPage && (
        <>
          <ImageSlider />
          <CollectionSection />
          <CategorySection />
          <StyleFinder/>
          <ProductSection />
        
       
        </>
      )}

      {/* Show Footer for all pages except Checkout */}
      {!isCheckoutPage && <Footer />}
    </div>
  );
}

export default App;
