// ProductsData.js
export const Producdata = [
    {
      id: 1,
      title: "Light Grey Regular Fit Mens Jean",
      image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/s/k/u/l-st1-vebnor-original-imah5hf9fwhx25gh.jpeg?q=70&crop=false",
      images: [
        "https://myraymond.com/cdn/shop/collections/3._T-shirt.png?v=1710821824",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/sweatshirt/h/b/r/s-aw18-rn-swtshrt-grey-navy-dark-maniac-original-imah2z3hhardze9b.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/q/w/9/l-sc-sky-blue-maan-international-original-imah4grmzvsndjgj.jpeg?q=70&crop=false",
        "https://myraymond.com/cdn/shop/collections/3._T-shirt.png?v=1710821824",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/sweatshirt/h/b/r/s-aw18-rn-swtshrt-grey-navy-dark-maniac-original-imah2z3hhardze9b.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/q/w/9/l-sc-sky-blue-maan-international-original-imah4grmzvsndjgj.jpeg?q=70&crop=false"
      ],
      subtitle: "Sab Title",
      discountPrice: "999.00",
      mainPrice: "1499",
      
      description: "Comfortable and stylish regular fit jeans with a light grey shade. Perfect for casual outings."
    },
    {
      id: 2,
      title: "Men Full Sleeve Printed Sweatshirt",
      image: "https://rukminim1.flixcart.com/image/1750/2100/xif0q/shirt/g/c/f/xl-frml-st27-vebnor-original-imah4gpp2fzgzyy6.jpeg?q=20&crop=false",
      images: [
        "https://rukminim2.flixcart.com/image/832/832/xif0q/sweatshirt/h/b/r/s-aw18-rn-swtshrt-grey-navy-dark-maniac-original-imah2z3hhardze9b.jpeg?q=70&crop=false",
        "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/q/w/9/l-sc-sky-blue-maan-international-original-imah4grmzvsndjgj.jpeg?q=70&crop=false",
        "https://myraymond.com/cdn/shop/collections/3._T-shirt.png?v=1710821824",
        "https://myraymond.com/cdn/shop/collections/3._T-shirt.png?v=1710821824"
      ],
      subtitle: "MANIAC",
      discountPrice: "799.00",
      mainPrice: "1499",
  
      description: "Trendy full sleeve sweatshirt with printed design, perfect for casual wear during colder seasons."
    },
    {
        id: 3,
        title: "Men Regular Fit Solid Spread Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/q/w/9/l-sc-sky-blue-maan-international-original-imah4grmzvsndjgj.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/sweatshirt/h/b/r/s-aw18-rn-swtshrt-grey-navy-dark-maniac-original-imah2z3hhardze9b.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/q/w/9/l-sc-sky-blue-maan-international-original-imah4grmzvsndjgj.jpeg?q=70&crop=false",
          "https://myraymond.com/cdn/shop/collections/3._T-shirt.png?v=1710821824",
          "https://myraymond.com/cdn/shop/collections/3._T-shirt.png?v=1710821824"
        ],
        subtitle: "Maan International",
        discountPrice: "788.00",
        mainPrice: "2499",
       
        description: "A stylish, solid spread collar casual shirt for men, perfect for any casual occasion."
      },      
      {
        id: 4,
        title: "Men Regular Fit Solid Spread Collar Formal Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "Ramraj Cotton",
        discountPrice: "658.00",
        mainPrice: "999",
        description: "Classic formal shirt with a spread collar and solid design, made from soft Ramraj cotton."
      },
      {
        id: 5,
        title: "Men Regular Fit Solid Spread Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "WOXEN",
        discountPrice: "1355.00",
        mainPrice: "2099",
        description: "Stylish casual shirt with a solid design and spread collar from WOXEN."
      },
      {
        id: 6,
        title: "Men Slim Fit Checkered Slim Collar Casual Shirt#JustHere",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "Tanip",
        discountPrice: "1219.00",
        mainPrice: "1399",
        
        description: "Slim fit checkered shirt with a slim collar, perfect for casual wear."
      },
      {
        id: 7,
        title: "Men Regular Fit Solid Mandarin Collar Casual Shirt",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false"
        ],
        subtitle: "RAHUL LOOK",
        discountPrice: "1269.00",
        mainPrice: "2099",
        description: "Solid mandarin collar casual shirt for a trendy and modern look."
      },
      {
        id: 8,
        title: "Men Regular Fit Solid Spread Collar Casual Shirt#JustHere",
        image: "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false",
        images: [
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/c/3/0/s-st2-vebnor-original-imah4y67hnf4q2fg.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/k/5/a/50-whitediamond1pf-ramraj-cotton-original-imagyt4cze7anyng.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/4/h/5/xl-dpblack-woxen-original-imah3hhxckfzynfv.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/o/o/n/xl-ds-shirt1242-s-dark-age-original-imagujxtqja6shfa.jpeg?q=70&crop=false",
          "https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/5/y/xxl-lst-cnz-black-ethnic-trendz-original-imah3wswjf9hgrvh.jpeg?q=70&crop=false"
        ],
        subtitle: "Vebnor",
        discountPrice: "1299.00",
        mainPrice: "1599",
    
        description: "Solid spread collar casual shirt, perfect for a comfortable and trendy look."
      }
  ];
  