import React from 'react';
import useStyleFinder from './useStyleFinder';
import './styleFinder.css';
import { motion } from "framer-motion";

const initialProducts = [
  { id: 1,   img: 'https://rukminim2.flixcart.com/image/832/832/xif0q/shirt/j/l/d/l-frml-st2-vebnor-original-imah7n4exqgmpjyg.jpeg?q=70&crop=false' },
  { id: 2,  img: 'https://rukminim2.flixcart.com/image/832/832/xif0q/blazer/a/s/p/40-d475-man-style-original-imah3gvsru3cafhm.jpeg?q=70&crop=false' },
  { id: 3,   img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/kurta/8/n/v/l-sl-navy-blue-lyplo-original-imahfeffuzyjztha.jpeg?q=70' },
  { id: 4,  img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shirt/c/3/g/xl-casual-satin-silk-shirts-for-men-sam-unique-man-original-imah7hwghddeh9jq.jpeg?q=70' },
  { id: 5,  img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/suit/j/h/i/-original-imaghkcmn7syz78z.jpeg?q=70' },
  
  { id: 7,   img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/jacket/m/p/o/xl-no-heavy-upar-0-futse-peno-original-imah4ymbzkqbh7v8.jpeg?q=70' },
  { id: 8,  img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/short/6/0/p/28-prm-6003-capri-sky-fubar-original-imahyfr5gykywstt.jpeg?q=70' },
];

const CardCarousel = () => {
  const { currentItems, handleNext, handlePrevious } = useStyleFinder(initialProducts);

  return (
    <motion.div className='upperdiv' 
    initial={{ opacity: 0, y: -50 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 1.9 }}> <h1>Style For you</h1>
    <div className="carousel-container">
       
      <button className="carousel-btn left-btnn" onClick={handlePrevious}>
        &lt;
      </button>
      <div className="carousel">
        {currentItems.map((item, index) => {
          let positionClass = '';
          switch (index) {
            case 0:
            case 4:
              positionClass = 'far-side';
              break;
            case 1:
            case 3:
              positionClass = 'side';
              break;
            case 2:
              positionClass = 'center';
              break;
            default:
              positionClass = 'hidden';
          }

          return (
            <div key={item.id} className={`card ${positionClass}`}>
              <img src={item.img} alt={item.name} />
           
            </div>
          );
        })}
      </div>
      <button className="carousel-btn right-btnn" onClick={handleNext}>
        &gt;
      </button>
    </div></motion.div>
  );
};

export default CardCarousel;
