// ProductsData.js
export const oldproducts = [
    {
      id: 1,
      title: "Brown Mandarin Collar Shirt For Men",
      image: "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/brown_mandarin_collar_shirt_for_men_base_07_09_2024_700x933.jpg",
      images: [
        "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/brown_mandarin_collar_shirt_for_men_base_07_09_2024_700x933.jpg",
        "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/brown_mandarin_collar_shirt_for_men_back_07_09_2024_700x933.jpg",
        "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/brown_mandarin_collar_shirt_for_men_flatlay_1_07_09_2024_700x933.jpg",
        "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/brown_mandarin_collar_shirt_for_men_full_view_07_09_2024_100x133.jpg",
       "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/brown_mandarin_collar_shirt_for_men_zoom_view_07_09_2024_100x133.jpg"
      ],
      subtitle: "Brown Mandarin",
      discountPrice: "849.00",
      mainPrice: "1699",
      description: "Comfortable and stylish regular fit jeans with a light grey shade. Perfect for casual outings."
    },
    {
      id: 2,
      title: "Light Grey Utility Pockets Shirt For Men",
      image: "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_utility_pockets_shirt_for_men_base_10_07_2024_700x933.jpg",
      images: [
        "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_utility_pockets_shirt_for_men_full_view_10_07_2024_700x933.jpg",
        "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_utility_pockets_shirt_for_men_neck_view_10_07_2024_700x933.jpg",
        "https://www.beyoung.in/api/cache/catalog/products/31-8-2024/light_grey_utility_pockets_shirt_for_men_american_placket_700x933.jpg",
        "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/light_grey_utility_pockets_shirt_for_men_fabric_view_02_01_2025_700x933.jpg"
      ],
      subtitle: " Grey Utility",
      discountPrice: "1399.00",
      mainPrice: "3099",
      
      description: "Trendy full sleeve sweatshirt with printed design, perfect for casual wear during colder seasons."
    },
    {
        id: 3,
        title: "Dark Grey Contrast Stitch Jacket Shirt for Men",
        image: "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/dark_grey_contrast_stitch_jacket_shirt_for_men_base_10_07_2024_700x933.jpg",
        images: [
          "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/dark_grey_contrast_stitch_jacket_shirt_for_men_full_view_10_07_2024_700x933.jpg",
          "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/dark_grey_contrast_stitch_jacket_shirt_for_men_side_view_10_07_2024_700x933.jpg",
          "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/dark_grey_contrast_stitch_jacket_shirt_for_men_back_10_07_2024_700x933.jpg",
          "https://beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/dark_grey_contrast_stitch_jacket_shirt_for_men_back_zoom_10_07_2024_700x933.jpg"
        ],
        subtitle: "Contrast Stitch",
        discountPrice: "1199.00",
        mainPrice: "2999",
        
        description: "A stylish, solid spread collar casual shirt for men, perfect for any casual occasion."
      },      
      {
        id: 4,
        title: "Men Regular Fit Solid Spread Collar Formal Shirt",
        image: "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/crocodile_green_geometric_print_half_sleeve_mens_shirt_base_700x933.jpg",
        images: [
          "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/crocodile_green_geometric_print_half_sleeve_mens_shirt_flatlay_2_700x933.jpg",
          "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/crocodile_green_geometric_print_half_sleeve_mens_shirt_fabric_view_02_01_2025_700x933.jpg",
          "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/crocodile_green_geometric_print_half_sleeve_mens_shirt_flatlay_1_700x933.jpg",
          "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/crocodile_green_geometric_print_half_sleeve_mens_shirt_back_12_07_2024_700x933.jpg",
          "https://www.beyoung.in/api/cache/catalog/products/new_shirt_upload_21_10_2022/crocodile_green_geometric_print_half_sleeve_mens_shirt_base_700x933.jpg",
          ],
        subtitle: "Crocodile Green",
        discountPrice: "649.00",
        mainPrice: "1999",
     
        description: "Classic formal shirt with a spread collar and solid design, made from soft Ramraj cotton."
      },
      {
        id: 5,
        title: "(Khaki, M) Fashion Men's Spring and Autumn Casual",
        image: "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcSCVmG1t533TlyReaLEbbPk-8jYTAbIJ--WAEcFtY1atwr_ujaM0hbxAsstRXiB66RQAaP27X_lmEQBfoRvwkKbZHbeXOCTEbr-3mLY9ScPk0KDzMY365NdMQ&usqp=CAE",
        images: [
          "https://encrypted-tbn0.gstatic.com/shopping?q=tbn:ANd9GcRebZU3V0FLuBk9k2vt31e-ot2Qpy0kVmW0e0tllthe9POfG9rXO3Vbn9xp0whJB9gEPQFpKLlM0nZrnsmkI5GXKwn16t1ezA&usqp=CAY",
          "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcR3h2WT5S79M8DlCQTfFPQanp7CoO_plWDTyzhi2ufMWQfjq72HlRMq5t_83MgooRwrGYJo-qNkHbnytQJjHsc8f_-iyQTTug&usqp=CAY",
          "https://encrypted-tbn3.gstatic.com/shopping?q=tbn:ANd9GcTlSGAlRD-KXE1vN9SBXaW0YZckXlgMAcPFkDcTEHcuweV_sfNM5dLqjC7tWh3kfAvLy8lA5XH_-5DZ7uUaXQfyMV4wiZaAGg&usqp=CAY",
          "https://encrypted-tbn2.gstatic.com/shopping?q=tbn:ANd9GcRKJPSJ-mAOQ4yWrXx_mf0PEruG3UQcAJqlbW1GNPj0OrbRxHbseggzR7Q2R14Hr4It5tDq0SkNWXDknfzyu5yi6Sl9X5UU&usqp=CAY",
              ],
        subtitle: "Long Sleeve",
        discountPrice: "999.00",
        mainPrice: "2049.07",
       
        description: "Stylish casual shirt with a solid design and spread collar from WOXEN."
      },
      {
        id: 6,
        title: "Plaid: Brown Mustard And Black",
        image: "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1700648768_8646421.jpg?v=2",
        images: [
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1700648768_8646421.jpg?v=2",
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1700648768_5389924.jpg?v=2",
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1700648768_2189730.jpg?v=2",
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1700648768_9821718.jpg?v=2",],
        subtitle: "Men Relaxed Shirts",
        discountPrice: "699.00",
        mainPrice: "1799",
        
        description: "Slim fit checkered shirt with a slim collar, perfect for casual wear."
      },
      {
        id: 7,
        title: "Plaid: Black, Blue and Red",
        image: "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710420120_8593675.jpg?v=2",
        images: [
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710420120_8593675.jpg?v=2",
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710420120_8755334.jpg?v=2",
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710420120_9652172.jpg?v=2",
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710420120_6460095.jpg?v=2",
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1710420120_6825728.jpg?v=2"
        ],
        subtitle: "Men Relaxed Shirts",
        discountPrice: "699.00",
        mainPrice: "1699",
      
        description: "Solid mandarin collar casual shirt for a trendy and modern look."
      },
      {
        id: 8,
        title: "Plaid: Blue And White",
        image: "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1712574491_5770981.jpg?v=2",
        images: [
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1712574491_5770981.jpg?v=2",
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1695283628_4925949.jpg?v=2",
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1695283628_6557964.jpg?v=2",
          "https://prod-img.thesouledstore.com/public/theSoul/uploads/catalog/product/1695283628_8926164.jpg?v=2",
         ],
        subtitle: "Vebnor",
        discountPrice: "1499.00",
        mainPrice: "1599",
        
        description: "Solid spread collar casual shirt, perfect for a comfortable and trendy look."
      }
  ];
  