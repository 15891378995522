import React from "react";
import "./ProductPage.css";
import Productsectionforall from "./Productsectionforall";
import { oldproducts } from "./oldfashindata";



const ProductPage = () => { 
  return (
    <div className="product-page">
      {/* Hero Section */}
      <section className="heroo">
        <div className="hero-content">
          <h1>"Rediscover Timeless Elegance with Vintage Classics"</h1>
          <p>"Celebrate the charm of classic styles that never go out of fashion. Relive the past with a modern touch."</p>
          <button className="shop-now">Shop Now</button>
        </div>
      </section>
      
      <Productsectionforall products={oldproducts}/>
    
    </div>
  );
};

export default ProductPage;
