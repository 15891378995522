import { useState } from 'react';

const useStyleFinder = (items) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex - 1 + items.length) % items.length
    );
  };

  const getVisibleItems = () => {
    const visibleItems = [];
    for (let i = 0; i < 5; i++) {
      visibleItems.push(items[(currentIndex + i) % items.length]);
    }
    return visibleItems;
  };

  const currentItems = getVisibleItems();

  return { currentItems, handleNext, handlePrevious };
};

export default useStyleFinder;
